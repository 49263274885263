import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles, Link, Avatar, Menu, MenuItem, Divider } from "@material-ui/core"
import { Auth } from "aws-amplify"
import { navigate } from "@reach/router"
import clsx from "clsx"

const styles = (theme: Theme) =>
  createStyles({
    right: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer"
    },
    rightLink: {
      fontSize: 16,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(3)
    },
    linkSecondary: {
      color: theme.palette.secondary.contrastText
    },
    avatar: {
      margin: 5
      // width: 60,
      // height: 60,
    }
  })

interface ProfileMenuProps extends WithStyles<typeof styles> {}

const ProfileMenu = (props: ProfileMenuProps) => {
  const { classes } = props
  const [profileEl, setProfileEl] = React.useState<null | HTMLElement>(null)
  const [currentUser, setCurrentUser] = React.useState(null)

  React.useEffect(() => {
    getCurrentUser().then(user => setCurrentUser(user))
  }, [])

  const getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      return user
    } catch (err) {
      console.log(err)
      return null
    }
  }

  const OpenProfileMenu = event => setProfileEl(event.currentTarget)
  const closeProfileMenu = () => setProfileEl(null)

  const logout = async () => {
    try {
      await Auth.signOut()
      setCurrentUser(null)
      closeProfileMenu()
      navigate("/")
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className={classes.right}>
      {!currentUser && (
        <>
          <Link color="inherit" variant="h6" className={classes.rightLink} onClick={() => navigate("/sign-in")}>
            {"Sign in"}
          </Link>
          <Link
            variant="h6"
            className={clsx(classes.rightLink, classes.linkSecondary)}
            onClick={() => navigate("/sign-up")}
          >
            {"Sign Up"}
          </Link>
        </>
      )}
      {!!currentUser && (
        <>
          <Avatar
            alt={currentUser.attributes.email}
            src={`https://www.tinygraphs.com/squares/${currentUser.attributes.email}@yyy.com?theme=summerwarmth&numcolors=4&size=30&fmt=svg`}
            className={classes.avatar}
            onClick={OpenProfileMenu}
          />
          <Menu
            id="simple-menu"
            anchorEl={profileEl}
            keepMounted
            open={Boolean(profileEl)}
            onClose={closeProfileMenu}
            style={{ zIndex: 99991 }}
          >
            <MenuItem onClick={closeProfileMenu} disabled={true}>
              {currentUser.attributes.email}
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                closeProfileMenu()
                navigate("/sign-change-password")
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </>
      )}
    </div>
  )
}
export default withStyles(styles)(ProfileMenu)
