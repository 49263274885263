// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:cffba9ea-ec49-410d-a635-669d36f00c75",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_DWikGgh7P",
    "aws_user_pools_web_client_id": "4tfsujljklgmqaoc3b3gq3v2ml",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://iq73wrmg65hyfjyoewmgtpaa4y.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "emailapi",
            "endpoint": "https://xjh9831sbk.execute-api.ap-southeast-2.amazonaws.com/learnesp",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
