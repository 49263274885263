import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Hidden,
  Link
} from "@material-ui/core"
import clsx from "clsx"
//import { Link } from "gatsby"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { navigate } from "gatsby"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ProfileMenu from "./ProfileMenu"

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      cursor: "pointer"
    },
    toolbar: {
      justifyContent: "space-between"
    },
    left: {
      flex: 1,
      color: theme.palette.common.white,
      cursor: "pointer"
    },
    leftLinkActive: {
      color: theme.palette.common.white
    },
    leftLink: {
      marginLeft: 24,
      whiteSpace: "nowrap"
    },

    menuButton: {
      marginRight: 16
    },
    lastLink: {
      paddingRight: 24
    },
    active: {
      fontWeight: "bold"
    }
  })

interface AppBarProps extends WithStyles<typeof styles> {}

function AppAppBar(props: AppBarProps) {
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)
  const OpenMenu = event => setMenuEl(event.currentTarget)
  const CloseMenu = () => setMenuEl(null)
  const goNavigate = (url: string) => {
    CloseMenu()
    navigate(url)
  }

  const linkStyle = (path: string) => {
    if (typeof window === `undefined`) {
      return classes.leftLink
    }

    if (window.location.pathname.localeCompare(path) === 0) {
      return `${classes.leftLink} ${classes.active}`
    }
    return classes.leftLink
  }

  const { classes } = props

  return (
    <div>
      <Hidden smUp implementation="css">
        <AppBar position="sticky">
          <Toolbar className={classes.toolbar}>
            <div className={classes.left}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                aria-controls="menu-items"
                aria-haspopup="true"
                onClick={OpenMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-items"
                anchorEl={menuEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={!!menuEl}
                onClose={CloseMenu}
              >
                <MenuItem onClick={() => goNavigate("/")}>Home</MenuItem>
                <MenuItem onClick={() => goNavigate("/course")}>Course</MenuItem>
                <MenuItem onClick={() => goNavigate("/stripe-checkout")}>Price</MenuItem>
                <MenuItem onClick={() => goNavigate("/contact-us")}> Contact Us</MenuItem>
              </Menu>
            </div>
            <ProfileMenu />
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden xsDown implementation="css">
        <AppBar position="sticky" style={{ zIndex: 9999 }}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.left}>
              <Link variant="h6" color="inherit" onClick={() => navigate("/course")} className={linkStyle("/course")}>
                Course
              </Link>
              <Link
                variant="h6"
                color="inherit"
                className={linkStyle("/stripe-checkout")}
                onClick={() => navigate("/stripe-checkout")}
              >
                Price
              </Link>
              <Link
                variant="h6"
                color="inherit"
                className={linkStyle("/contact-us") + " " + classes.lastLink}
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </Link>
            </div>

            <Link variant="h6" underline="none" color="inherit" className={classes.title} onClick={() => navigate("/")}>
              LEARN ESP32
            </Link>
            <ProfileMenu />
          </Toolbar>
        </AppBar>
      </Hidden>
    </div>
  )
}

export default withStyles(styles)(AppAppBar)
