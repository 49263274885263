import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles, TextField } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

//images

import appFooterFacebook from "../assets/themes/onepirate/appFooterFacebook.png"
import appFooterTwitter from "../assets/themes/onepirate/appFooterTwitter.png"
import slackFooter from "../assets/icons/logos/Slack_Mark_Web.png"
import meetup from "../assets/icons/logos/meetup.svg"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: theme.palette.secondary.light
    },
    container: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      display: "flex"
    },
    iconsWrapper: {
      height: 120
    },
    icons: {
      display: "flex"
    },
    logo: {
      width: 50,
      height: 50,
      marginRight: theme.spacing(1),
      "&:hover": {
        transform: "scale(1.1)"
      }
    },

    list: {
      margin: 0,
      listStyle: "none",
      paddingLeft: 0
    },
    listItem: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    language: {
      marginTop: theme.spacing(1),
      width: 150
    }
  })

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link to="/learnesp32.com">Learn ESP32</Link> {new Date().getFullYear()}
    </React.Fragment>
  )
}

const LANGUAGES = [
  {
    code: "en-US",
    name: "English"
  },
  {
    code: "fr-FR",
    name: "Français"
  }
]

interface AppFooterProps extends WithStyles<typeof styles> {}

const AppFooter = (props: AppFooterProps) => {
  const { classes } = props

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <div>
              <a href="https://join.slack.com/t/melbourneesp32/shared_invite/enQtNzMyMDYxNjM1MjIzLTZiZDY0YWY3ZWI3ODYzMTk1ZWNkMDIyMjE5Y2QxYzIzYjE2YTMwNDA5MGZkOTRkNTgxYjAxNDJkZDVlZDc2MzE">
                <img src={slackFooter} alt="Slack" className={classes.logo} />
              </a>
              <a href="https://www.meetup.com/Melbourne-ESP32-Meetup-Group/">
                <img src={meetup} alt="Twitter" className={classes.logo} />
              </a>
            </div>

            <Grid item>
              <Copyright />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Typography variant="h6" align="left" gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link to="/terms-of-use">Terms</Link>
              </li>
              <li className={classes.listItem}>
                <Link to="/privacy-policy">Privacy</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Typography variant="h6" align="left" gutterBottom>
              Contact
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link to="/terms-of-use">contact-us</Link>
              </li>
            </ul>
          </Grid>
          {/* <Grid item xs={6} sm={8} md={4}>
            <Typography variant="h6" align="left" gutterBottom>
              Language
            </Typography>
            <TextField
              SelectProps={{
                native: true
              }}
              className={classes.language}
            >
              {LANGUAGES.map(language => (
                <option value={language.code} key={language.code}>
                  {language.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {"Icons made by "}
              <Link to="https://www.freepik.com" rel="nofollow" title="Freepik">
                Freepik
              </Link>
              {" from "}
              <Link to="https://www.flaticon.com" rel="nofollow" title="Flaticon">
                www.flaticon.com
              </Link>
              {" is licensed by "}
              <Link
                to="https://creativecommons.org/licenses/by/3.0/"
                title="Creative Commons BY 3.0"
                target="_blank"
                rel="noopener noreferrer"
              >
                CC 3.0 BY
              </Link>
            </Typography>
          </Grid> */}
        </Grid>
      </Container>
    </Typography>
  )
}
export default withStyles(styles)(AppFooter)
