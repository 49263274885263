import * as React from "react"
import { ThemeProvider } from "@material-ui/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import theme from "../styles/theme"
import IndexLayout from "../layouts"
import AppAppBar from "../components/AppAppBar"
import Amplify from "aws-amplify"
import { SnackbarProvider } from "notistack"
import AppFooter from "../components/AppFooter"
//config aws cognito
import awsConfig from "../aws-exports"
Amplify.configure(awsConfig)

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <IndexLayout>
            <CssBaseline />
            <>
              <AppAppBar />
              <Component {...props} />
              <AppFooter />
            </>
          </IndexLayout>
        </ThemeProvider>
      </SnackbarProvider>
    )
  }
  return WithRoot
}

export default withRoot
